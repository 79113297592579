/**
 * Transforms CSS by adding a scope class to all selectors and removing body and top level * styles.
 *
 * @param css - The CSS string to transform.
 * @param scopeClass - The scope class to add to the selectors.
 * @returns The transformed CSS string.
 */
export function transformCSS(css = '', scopeClass) {
  let transformedCss = css;
  // Remove body and top level *
  transformedCss = transformedCss.replace(/body\s*\{[^}]*\}/g, '');
  transformedCss = transformedCss.replace(/html\s*\{[^}]*\}/g, '');
  transformedCss = transformedCss.replace(/\*\s*\{[^}]*\}/g, '');

  // Add the hierarchy of .scope-class-123 to all styles
  transformedCss = transformedCss.replace(/([^{}]+)\{/g, (_match, selectors) => {
    if (selectors.includes('@media')) {
      return `${selectors} { `;
    }

    const transformedSelectors = selectors
      .split(',')
      .map((selector) => `${scopeClass} ${selector.trim()}`)
      .join(', ');
    return `${transformedSelectors} {`;
  });

  return transformedCss;
}
