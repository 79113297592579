import { makeStyles } from '@material-ui/core/styles';

export const useSlidePreviewStyles = makeStyles((theme) => ({
  previewRoot: {
    display: 'flex',
    width: (params) => (params.width ? `${params.width}px` : '100%'),
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      flex: '1',
      marginBottom: '8px',
    },
  },
  previewWrap: {
    width: (params) => (params.width ? `${params.width}px` : '100%'),
    position: 'relative',
    paddingTop: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      paddingTop: '50%',
    },
  },
  previewWrapAdvanced: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  scaledContent: {
    width: '1280px', // Set the desired original width
    transform: (params) =>
      params.width ? `scale(${params.width / 1280})` : `scale(${(params.trackedWidth || 0) / 1280})`, // Adjust the scale as needed
    transformOrigin: 'top left', // Adjust the origin as needed
    overflow: 'hidden', // Ensure content doesn't overflow the parent container
    position: 'absolute',
    top: '0',
    left: '0',
    [theme.breakpoints.down('sm')]: {
      transform: (params) => `scale(${(params.trackedWidth || params.width || 0) / 1280})`, // Adjust the scale as needed
    },
  },
  previewInnerAdvanced: {
    width: '100%',
    height: '100%',
  },
}));

export const useInstructionTemplateStyles = makeStyles((theme) => ({
  buttonRoot: {
    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
  card: {
    width: '100%',
    position: 'relative',
  },
  contentWrap: {
    position: 'relative',
  },
  cardMediaWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'unset',
    textIndent: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: 0,
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  infoButton: {
    zIndex: 10,
  },
  infoPanel: {
    position: 'absolute',
    overflow: 'hidden',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
  },
  infoPanelAdvanced: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    marginTop: theme.spacing(1),
  },
  cardContent: {
    flex: 1,
  },
  cardActions: {
    background: '#fff',
  },
}));
