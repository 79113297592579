/* eslint-disable no-console */
import { useState, useEffect } from 'react';

function isImageHidden(imageElement) {
  const style = getComputedStyle(imageElement);

  // Check if width is greater than 0
  const isWidthZero = parseInt(style.width, 10) === 0;

  // Check if height is greater than 0
  const isHeightZero = parseInt(style.height, 10) === 0;

  // Check if display property is "none"
  const isDisplayNone = style.display === 'none';

  // Combine the conditions
  const isHidden = isWidthZero || isHeightZero || isDisplayNone;

  return isHidden;
}

const endpointInitial = 'https://api.d-simple.com/api';

/**
 * Tracks whether all images with selector have been fully loaded
 * Returns array of outstanding ids
 */
export const useImageCallIds = (condition, selector, token) => {
  const [imageCallIds, setImageCallIds] = useState([]);
  const [autoRelease, setAutoRelease] = useState(false);

  let timer = null;

  // per loaded image, remove id from array
  const onImageLoaded = (event) => {
    const id = typeof event === 'string' ? event : event?.target?.id;
    setImageCallIds((prevIds) => {
      return prevIds.filter((prevId) => prevId !== id);
    });
  };

  // per failed image, attempt to retry with auth token
  const handleInterceptCalls = (elements, viewToken) => {
    elements.forEach((element) => {
      const originalSource = element.src;

      const isValidEndpoint = originalSource.includes(endpointInitial);

      if (isValidEndpoint) {
        // overwrite src to not show the broken image icon
        // eslint-disable-next-line no-param-reassign
        element.src = '';

        fetch(originalSource, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: `Bearer ${viewToken}`,
          },
          credentials: 'include',
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            // eslint-disable-next-line no-param-reassign
            element.src = url; // Attach the new image URL to the img element
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error refetching the image:', error);
          });
      }
    });
  };

  // setup event listener per specified selector and cleanup if component unmounts
  useEffect(() => {
    const images = document.querySelectorAll(selector);

    const ids = [];

    images?.forEach((element) => {
      if (!element.complete && !isImageHidden(element)) {
        ids.push(element?.id);
        element.addEventListener('load', onImageLoaded);
      }
    });

    if (token && images) {
      handleInterceptCalls(images, token);
    }

    setImageCallIds(ids);

    return () => {
      images?.forEach((element) => {
        if (!element.complete) {
          element.removeEventListener('load', onImageLoaded);
        }
      });
    };
  }, [condition, token]);

  // when condition changes, reset autoRelease and set timer to set to true after x amount
  useEffect(() => {
    setAutoRelease(false);
    if (timer) {
      timer = null;
    }

    timer = setTimeout(() => {
      setAutoRelease(true);
    }, 60000);

    return () => {
      timer = null;
    };
  }, [condition, timer]);

  return { imageCallIds, autoRelease };
};
