import { Box, Card, CardActions, IconButton, Typography } from '@material-ui/core';
import { ArrowBackRounded, ArrowForwardRounded } from '@material-ui/icons';
import * as React from 'react';
import { useInstructionTemplateStyles } from './styles';
import { useImageCallIds } from './useImageCallIds';
import { SlidePreview, SLIDE_PREVIEW_EL_ID } from './SlidePreview';

const bearerToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiaW50ZXJuYWwtYXV0aCIsIm9yZ2FuaXNhdGlvbklkIjoiNjIyNjVhZWQyN2JhYzUwMDEzNDg0OGY0IiwiaW50ZXJuYWxBdXRoUGVybWlzc2lvbnMiOlt7InNsdWciOiJ1bW9fdGVtcGxhdGVzX2xpc3RfZmluZCIsInBlcm1pdHRlZCI6dHJ1ZX1dLCJpYXQiOjE3MjI1MzkxMDYsImV4cCI6MTg4MDMyNzEwNn0._yg07QPW1fI0v9KBNXF5uj3l_mx_fPiNrib1S5kWFBY';
// 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbmlzYXRpb25JZCI6IjYyMjY1YWVkMjdiYWM1MDAxMzQ4NDhmNCIsInR5cGUiOiJvcGVuLWluc3RydWN0aW9uIiwiaWF0IjoxNjgxMjA2OTQyLCJleHAiOjE4Mzg5OTQ5NDJ9.BbvhoM04MPvKDvmw23_Vs9O_8vGlGONtwkcFuE02Jl0';

export const InstructionTemplate = ({ template }) => {
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);

  const classes = useInstructionTemplateStyles();
  useImageCallIds(activeSlideIndex, `#${SLIDE_PREVIEW_EL_ID} img`, bearerToken);

  return (
    <Card className={classes.card} variant="outlined">
      <Box p={2}>
        <Typography className={classes.ellipsis} title={template.title} variant="h5">
          {template.title}
        </Typography>
      </Box>
      <Box className={classes.contentWrap}>
        <div className={classes.cardMediaWrap}>
          <SlidePreview key={activeSlideIndex} slide={template.slides[activeSlideIndex]} id={template.id} />
        </div>
      </Box>

      <Box className={classes.infoPanelAdvanced}>
        <CardActions className={classes.cardActions}>
          <Box>
            {template.slides.length > 1 && (
              <>
                <IconButton
                  size="small"
                  disabled={activeSlideIndex === 0}
                  onClick={() => {
                    if (activeSlideIndex !== 0) {
                      setActiveSlideIndex(activeSlideIndex - 1);
                    }
                  }}
                >
                  <ArrowBackRounded />
                </IconButton>
                <IconButton
                  size="small"
                  disabled={activeSlideIndex === template.slides.length - 1}
                  onClick={() => {
                    if (activeSlideIndex !== template.slides.length - 1) {
                      setActiveSlideIndex(activeSlideIndex + 1);
                    }
                  }}
                >
                  <ArrowForwardRounded />
                </IconButton>
              </>
            )}
          </Box>
        </CardActions>
      </Box>
    </Card>
  );
};
