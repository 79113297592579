/* eslint-disable no-useless-escape */
import React, { useState, useRef, useEffect } from 'react';
import lz from 'lzutf8';
import { Box, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { useSlidePreviewStyles } from './styles';
import { transformCSS } from './transformCSS';

export const SLIDE_PREVIEW_EL_ID = 'ds-sp-capture';

export const SlidePreview = (props) => {
  const { slide, id, width } = props;
  const theme = useTheme();
  const [trackedWidth, setTrackedWidth] = useState(null);

  const classes = useSlidePreviewStyles({ width, trackedWidth });

  const slideJson = lz.decompress(lz.decodeBase64(slide.data));
  const data = slideJson && JSON.parse(slideJson);
  const scopedClassName = `scoped-styles-${id}`;

  const prefixedCss = transformCSS(data.css, `.${scopedClassName}`);

  const resizeTimeoutRef = useRef(null);
  const previewRootRef = useRef(null);

  const handleResize = () => {
    if (previewRootRef.current) {
      setTrackedWidth(previewRootRef.current.clientWidth);
    }
  };

  const debouncedHandleResize = () => {
    if (window.innerWidth <= theme.breakpoints.values.md || !width) {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = setTimeout(() => {
        handleResize();
      }, 100); // Adjust the debounce delay as needed
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    debouncedHandleResize(); // Initial call to set the initial width

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <div ref={previewRootRef} id={SLIDE_PREVIEW_EL_ID} className={classes.previewRoot}>
      <Box className={classes.previewWrapAdvanced}>
        <style>{prefixedCss}</style>

        <Box className={clsx(classes.previewInnerAdvanced, scopedClassName)}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: data.html }} />
        </Box>
      </Box>
    </div>
  );
};
