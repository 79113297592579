import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { getImage } from 'gatsby-plugin-image';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import Seo from '../components/seo';
import { LandingPageLayout } from '../components/layout';
import { Section } from '../components/layout/Section';
import { UmoPromote } from '../components/UmoPromote';
import { InstructionTemplate } from '../components/InstructionTemplate/InstructionTemplate';

const PagePersonalHygiene = ({ pageContext, data }) => {
  const { bannerImageCall } = data;

  const { t } = useTranslation(['personal-hygiene']);
  const bannerImage = getImage(bannerImageCall);

  const template = data.allTemplate.nodes.find((node) => node.id === '642428b4fe802b001274d462');

  return (
    <>
      <Seo title={t('meta.title')} description={t('meta.description')} lang={pageContext.language} />

      <LandingPageLayout
        bannerImage={bannerImage}
        bannerTitle={t('banner.title')}
        bannerDescription={t('banner.description')}
      >
        <Section heading={t('heading.personalhygiene')}>
          <Typography>{t('content.personalhygiene')}</Typography>
          <Typography>{t('content.personalhygiene2')}</Typography>
          <Typography>{t('content.personalhygiene3')}</Typography>
          <Typography>{t('content.personalhygiene4')}</Typography>
          <Section headingVariant="h3" heading={t('heading.hygieneprodukte')}>
            <ol>
              {[
                t('content.inhaltHygieneprodukte1'),
                t('content.inhaltHygieneprodukte2'),
                t('content.inhaltHygieneprodukte3'),
                t('content.inhaltHygieneprodukte4'),
                t('content.inhaltHygieneprodukte5'),
                t('content.inhaltHygieneprodukte6'),
                t('content.inhaltHygieneprodukte7'),
              ].map((text) => {
                return (
                  <ListItem style={{ display: 'list-item' }} key={text}>
                    <ListItemText>{text}</ListItemText>
                  </ListItem>
                );
              })}
            </ol>
          </Section>
          <Section headingVariant="h3" heading={t('heading.infoHygieneprodukte')}>
            <ol>
              {[
                t('content.infoHygieneprodukte1'),
                t('content.infoHygieneprodukte2'),
                t('content.infoHygieneprodukte3'),
              ].map((text) => {
                return (
                  <ListItem style={{ display: 'list-item' }} key={text}>
                    <ListItemText>{text}</ListItemText>
                  </ListItem>
                );
              })}
            </ol>
            <Typography>{t('content.infoHygieneprodukte10')}</Typography>
          </Section>
        </Section>
        <Section heading={t('heading.template')}>
          <Typography>{t('content.template')}</Typography>

          <InstructionTemplate template={template} />
        </Section>
        <Section heading={t('heading.umopersonalhygiene')}>
          <Typography>{t('content.umopersonalhygiene')}</Typography>

          <UmoPromote />
        </Section>
      </LandingPageLayout>
    </>
  );
};
export default PagePersonalHygiene;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    bannerImageCall: file(relativePath: { eq: "shutterstock_71245138_neu.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
      }
    }
    allTemplate {
      nodes {
        id
        title
        slides {
          data
          type
        }
      }
    }
  }
`;
